
/* This file fixes the format of the navigation bar for IE9 since several 
   formatting details were not included with the media queries.*/

//Fixes alignment of topic links
.sub-nav-cat {
  display: inline-block;
  margin-right: 45px;
}

//Fixes height of the dark big block of the navigation bar
.contain-to-grid {  
  height:78px;
}

// Fixes background color, font, border, size, and position of search box
#nav-search-query {
  background: #4d4d4d;
  border-color: transparent;
  color: #e6e6e6; 
  font-weight: 300;
  padding-right: 30px;
  width: 100%;
}

// Fixes color, position, size of magnifying glass next to search bar
.button.expand {
  background-color:transparent; 
  left: 320px;
  height: 27px;
  position: absolute!important;
  width: 32px;
}

// Fixes color, font of topic (Home, Leadership, etc.) navigation bar
.visible-for-medium-up.sub-navbar {
  background-color: #4D4D4D;
  height: 28px;
}

// Fixes color and font of the topic navigation links
#main-nav .sub-navbar .sub-nav-cats .sub-nav-cat .sub-nav-link{
  color: #D7D7D7;
  font-size: 13px;  
}




